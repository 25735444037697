@font-face {
  font-family: 'website_font_3xp';
  src: url('../fonts/website_font_3xp.eot?65961624');
  src: url('../fonts/website_font_3xp.eot?65961624#iefix') format('embedded-opentype'),
       url('../fonts/website_font_3xp.woff2?65961624') format('woff2'),
       url('../fonts/website_font_3xp.woff?65961624') format('woff'),
       url('../fonts/website_font_3xp.ttf?65961624') format('truetype'),
       url('../fonts/website_font_3xp.svg?65961624#website_font_3xp') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'website_font_3xp';
    src: url('../font/website_font_3xp.svg?65961624#website_font_3xp') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "website_font_3xp";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-menu:before { content: '\e800'; } /* '' */
.icon-mail:before { content: '\e801'; } /* '' */
.icon-logo:before { content: '\e802'; } /* '' */
.icon-aplication:before { content: '\e803'; } /* '' */
.icon-about:before { content: '\e804'; } /* '' */
.icon-arrow_2:before { content: '\e805'; } /* '' */
.icon-arrow:before { content: '\e806'; } /* '' */
.icon-games:before { content: '\e807'; } /* '' */
.icon-memberlist:before { content: '\e808'; } /* '' */
.icon-teams:before { content: '\e809'; } /* '' */
.icon-board:before { content: '\e80a'; } /* '' */
.icon-boardchat:before { content: '\e80b'; } /* '' */
.icon-youtube:before { content: '\e80c'; } /* '' */
.icon-teamspeak:before { content: '\e80d'; } /* '' */
.icon-facebook-1:before { content: '\e80e'; } /* '' */
.icon-up-open-big:before { content: '\e80f'; } /* '' */
.icon-plus:before { content: '\e810'; } /* '' */
.icon-right-open-big:before { content: '\e811'; } /* '' */
.icon-news:before { content: '\e812'; } /* '' */
.icon-left-open-big:before { content: '\e813'; } /* '' */
.icon-down-open-big:before { content: '\e814'; } /* '' */
.icon-member:before { content: '\e815'; } /* '' */
.icon-server:before { content: '\e816'; } /* '' */
.icon-download:before { content: '\e81c'; } /* '' */
.icon-admin:before { content: '\e81d'; } /* '' */
.icon-ban:before { content: '\e81e'; } /* '' */
.icon-chat:before { content: '\e81f'; } /* '' */
.icon-clanpage:before { content: '\e820'; } /* '' */
.icon-home:before { content: '\e821'; } /* '' */
.icon-player:before { content: '\e822'; } /* '' */
.icon-cw:before { content: '\e82a'; } /* '' */
.icon-angle-left:before { content: '\e8d6'; } /* '' */
.icon-angle-right:before { content: '\e8d7'; } /* '' */
.icon-angle-up:before { content: '\e8d8'; } /* '' */
.icon-angle-down:before { content: '\e8d9'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-resize-full-alt:before { content: '\f0b2'; } /* '' */
